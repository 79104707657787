<template>
  <!-- BANNER TYPE 6 (泰極客製) -->
  <div
    id="banner-type6"
    class="mt-1 mb-2"
    :class="data.block"
  >
    <swiper
      ref="slider"
      class="swiper mx-6"
      :options="swiperOption"
    >
      <swiper-slide
        v-for="(banner,index) in banners"
        :key="banner._id + index"
      >
        <div
          v-show="banner.mobile_image"
          class="border-image-clip-path p-relative"
        >
          <v-img
            width="100%"
            class="slider-img"
            :src="baseImgUrl + banner.mobile_image || image"
            @click="clickBanner(banner.mobile_hyperlink, $router)"
          />
          <span class="p-absolute text--white right-0 bottom-0 thai-bg px-5 py-1 rounded-tl font-weight-bold">
            {{ $t('global.more') }}
          </span>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { clickBannerEv } from './clickBanner'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data: () => ({

  }),

  computed: {
    ...mapGetters(['baseImgUrl', 'theme']),

    ...mapState({
      bannerDelay: state => state.websiteData.settings.banner_interval,
    }),

    banners() {
      return this.data.data.banner
    },

    customPaginationColor() {
      const themePrimaryColor = this.$vuetify.theme.themes[this.theme]?.primary
      // 目前依 primary 顯色
      return {
        '--swiper-theme-color': themePrimaryColor || 'white',
      }
    },

    swiperOption() {
      return {
        autoplay: {
          delay: +this.bannerDelay * 1000 || 3000,
        },

        slidesPerView: 1,
        spaceBetween: 32,
        centeredSlides: true,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      }
    },
  },

  methods: {
    sliderNext() {
      this.$refs.slider.$swiper.slideNext()
    },
    slidePrev() {
      this.$refs.slider.$swiper.slidePrev()
    },

    clickBanner(hyperlink, router) {
      clickBannerEv(hyperlink, router)
    },
  },
}
</script>

<style lang="scss" scoped>

.swiper-button-prev,
.swiper-button-next {
	&::after {
		display: none;
	}

	i {
		font-size: 30px;
	}
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
	left: 20px;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
	right: 20px;
}

.border-image-clip-path {
position: relative;
border: 5px solid;
border-image: linear-gradient(45deg,#e6ce71 -5%, #bc954d 20%, #a57539 49%, #c09a50 77%, #e6ce71 100%) 1;
clip-path: inset(0 round 5px);
}
.right-0{
  right: 0;
}
.bottom-0{
  bottom: 0;
}
.thai-bg {
  background-image: linear-gradient(107deg, #e6ce71 -5%, #bc954d 20%, #a57539 49%, #c09a50 77%, #e6ce71 100%);
}
</style>
